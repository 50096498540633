import Sketch from "./module"
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import { MathUtils } from "three";
// import {SplitText} from "https://cdnjs.cloudflare.com/ajax/libs/gsap/3.10.3/gsap.min.js"
gsap.registerPlugin(ScrollTrigger);

let titles = [...document.querySelectorAll('.section')];

// titles.forEach(title=>{
//     mySplitText = new SplitText(title, { type: "words" })
//     gsap.from(mySplitText.words,{
//         duration: 0.5,
//         stagger: 0.02,
//         scale: 3,
//         autoAlpha: 0
//     })
// })


let sketch = new Sketch({
    dom: document.getElementById("container")
  });

  var aaa = sketch.aa;
  let o = {a:0};

//   gsap.to(sketch.face1, {rotation: 27, x: 100, duration: 1});

gsap.to(o,{
  a: 1,
  scrollTrigger: {
    trigger: ".wrap",
    // markers: true,
    // scrub: 2,
    start: "top top",
    end: "bottom bottom",
    // snap: 1/(titles.length - 1),
    onUpdate: (self)=>{
        // console.log(sketch.face1.position.x);
        
        // if (sketch.face1) sketch.face1.position.x = MathUtils.mapLinear(self.progress, 0, 0.25, 0, -sketch.width/200); 
        // if (sketch.face1) sketch.face1.position.x = -5*self.progress*3.14; 
        
        // if (sketch.face2) sketch.face2.position.x = MathUtils.mapLinear(self.progress, 0, 0.25, 0, -sketch.width/200); 
        // if (sketch.face2) sketch.face3.position.x = MathUtils.mapLinear(self.progress, 0, 0.25, 0, -sketch.width/200)*1.4;; 
        // if (sketch.pivot) sketch.pivot.position.x = -5.9*self.progress*3.14; 
        if (sketch.pivot1) sketch.pivot1.position.x = MathUtils.lerp(0,-15,self.progress*1.5); 
        // if (sketch.pivot1) sketch.pivot1.position.z = MathUtils.lerp(0,10,self.progress*1.5); 

        if (sketch.pivot2) sketch.pivot2.position.x = MathUtils.lerp(20,-15,self.progress); 
        // if (sketch.pivot2) sketch.pivot2.position.z = MathUtils.lerp(0,10,self.progress*1.5); 
        
        if (sketch.pivot3) sketch.pivot3.position.x = MathUtils.lerp(20,0,self.progress); 
        // if (sketch.pivot3) sketch.pivot3.position.z = MathUtils.lerp(0,10,self.progress*1); 
        // if (sketch.face3) sketch.face3.position.x = -5.1*self.progress*3.14; 


        // 2.*self.progress*3.14;
    }
  }
})