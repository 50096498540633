import 'regenerator-runtime/runtime'
import * as THREE from "three";
import { GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import { TimelineMax } from "gsap";
import model from "../model/ears.glb"
import model2 from "../model/mouth.glb"
import model3 from "../model/eyes.glb"
import eyest from "/images/eyes.png";
import earst from "/images/ears.png";
import moutht from "/images/mouth.png";
import soundT from "/sounds/sound.mp3"


import fragment from "./shaders/fragment.glsl";
import vertex from "./shaders/vertex.glsl";
import { MathUtils } from 'three';

// let OrbitControls = require("three-orbit-controls")(THREE);

// create an AudioListener and add it to the camera
const listener = new THREE.AudioListener();
// camera.add( listener );

// create a global audio source
const sound = new THREE.Audio( listener );
// var playing = false;

// load a sound and set it as the Audio object's buffer
const audioLoader = new THREE.AudioLoader();

    audioLoader.load( soundT, function( buffer ) {
      sound.setBuffer( buffer );
      sound.setLoop( true );
      sound.setVolume( 0.5 );
      // sound.play();
      
      
})

function audioplay(){
  if(!sound.isPlaying){
    sound.play();
  }
  else{
    sound.stop(); 
  }
  console.log(sound.isPlaying);

}


let analyser = new THREE.AudioAnalyser( sound, 128 );

const startButton = document.getElementById( 'audioSwitch' );
startButton.addEventListener( 'click', audioplay );




export default class Sketch {
  constructor(options) {
    this.sou = soundT;
    this.scene = new THREE.Scene();

    this.container = options.dom;
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.renderer.setClearColor(0x000000, 0); 
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    this.container.appendChild(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.001,
      1000
    );
    var PI = 3.141592653589793238;
    // var frustumSize = 10;
    // var aspect = window.innerWidth / window.innerHeight;
    // this.camera = new THREE.OrthographicCamera( frustumSize * aspect / - 2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / - 2, -1000, 1000 );
    this.camera.position.z = 3;
    this.camera.add( listener );
    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.time = 0;
    this.aa = 0;
    this.np = 10;

    //raycaster
    


    this.isPlaying = true;

   
    
    this.addObjects();
    this.resize();
    this.setupAudioContext();
    // this.onMouseMove();
    this.render();
    this.setupResize();
   
    // this.settings();

    this.loader = new GLTFLoader();
        this.loader.load(model, (gltf)=>{
            console.log(gltf);
            this.face1 = gltf.scene;
            this.pivot1 = new THREE.Group();
          
        this.pivot1.add( this.face1 );
        // this.pivot1.position.x =1;
        
            // this.scene.add(this.face1);
            gltf.scene.traverse(o=>{
                if(o.isMesh){
                    o.geometry.center();
                    o.scale.set(0.3,0.3,0.3);
                    o.material = this.material;
                    o.position.y=-0.1;
                    // o.position.z=-2;
                    // o.rotateZ(-Math.PI/2);
                    o.rotateX(-Math.PI/2);

                    // o.rotation.z = Math.PI/2;
                    
                    
                }
            })
            this.scene.add( this.pivot1 );  
        })

        

        this.loader.load(model2, (gltf)=>{
          console.log(gltf);
          this.face2 = gltf.scene;
          this.pivot2 = new THREE.Group();
          
          this.pivot2.add( this.face2 );
          this.pivot2.position.x =10;
          
          
          // this.scene.add(this.face2);
          gltf.scene.traverse(o=>{
              if(o.isMesh){
                    o.geometry.center();
                    o.scale.set(0.3,0.3,0.3);
                    o.material = this.material2;
                    o.position.y=-0.1;
                    // o.position.x=-5;
                    o.rotateZ(0.5);
                    o.rotateX(-Math.PI/2);
                  
                  
                  
              }
              this.scene.add( this.pivot2 );  
          })
          

          
          this.loader.load(model3, (gltf)=>{
            console.log(gltf);
            this.face3 = gltf.scene;
            this.pivot3 = new THREE.Group();
          
        this.pivot3.add( this.face3 );
        this.pivot3.position.x =10;
         
            // this.scene.add(this.face3);
            gltf.scene.traverse(o=>{
                if(o.isMesh){
                    o.geometry.center();
                    o.scale.set(0.3,0.3,0.3);
                    o.material = this.material3;
                    o.rotateX(-PI/2.1);
                    // o.position.x=-10;
                    // o.translate = (12,0,0);
                    
                    
                }
            })
            this.scene.add( this.pivot3 );  
        })

                  
          
          
      })

      // this.geometry = new THREE.SphereGeometry( 1, 30, 60 );
      // this.sphere = new THREE.Mesh( this.geometry, this.material ); 
      // this.scene.add( this.sphere );     
  } 

  setupAudioContext(){

    // const fftSize = 128;

    // this.audioContext = new window.AudioContext();
    // this.audioElement = document.getElementById("myAudio2");
    
    // this.listener = new THREE.AudioListener();
    // // camera.add( listener );

    // // create a global audio source
    // this.sound = new THREE.Audio( this.listener );

    // // load a sound and set it as the Audio object's buffer
    // this.audioLoader = new THREE.AudioLoader();
    // this.audioLoader.load( '/sound/sound.mp3', function( buffer ) {
    //   this.sound.setBuffer( buffer );
    //   this.sound.setLoop( true );
    //   this.sound.setVolume( 0.5 );
    //   this.sound.play();
    // });

    // this.source = this.audioContext.createMediaElementSource(this.audioElement);
    // this.analyser = this.audioContext.createAnalyser(); 
    // this.source.connect(this.analyser);
    // this.analyser.connect(this.audioContext.destination);
    // this.analyser.fftSize = 32;
    // this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);

  }

  settings() {
    let that = this;
    this.settings = {
      progress: 0,
    };
    this.gui = new dat.GUI();
    this.gui.add(this.settings, "progress", 0, 1, 0.01);
  }

  setupResize() {
    window.addEventListener("resize", this.resize.bind(this));
    // this.mousemouse = document.getElementById( 'container' );
    // this.mousemouse.addEventListener("mousemove", this.onMouseMove, false);
    // this.startButton = document.getElementById( 'container' );
		// this.startButton.addEventListener( 'click', this.setupAudioContext );
  }

  resize() {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
  }

  addObjects() {
    let that = this;
    this.material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { type: "f", value: 0 },
        aa: { type: "f", value: 0 },
        mountain: {type: "t", value: new THREE.TextureLoader().load(eyest)},
        resolution: { type: "v4", value: new THREE.Vector4() },
        uvRate1: {
          value: new THREE.Vector2(1, 1)
        }
      },
      // wireframe: true,
      // transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment
    });

    this.material2 = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { type: "f", value: 0 },
        aa: { type: "f", value: 0 },
        mountain: {type: "t", value: new THREE.TextureLoader().load(moutht)},
        resolution: { type: "v4", value: new THREE.Vector4() },
        uvRate1: {
          value: new THREE.Vector2(1, 1)
        }
      },
      // wireframe: true,
      // transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment
    });

    this.material3 = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { type: "f", value: 0 },
        aa: { type: "f", value: 0 },
        mountain: {type: "t", value: new THREE.TextureLoader().load(earst)},
        resolution: { type: "v4", value: new THREE.Vector4() },
        uvRate1: {
          value: new THREE.Vector2(1, 1)
        }
      },
      // wireframe: true,
      // transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment
    });

   

    // this.geometry = new THREE.SphereBufferGeometry(7.5, 32, 32);


    // this.plane = new THREE.Mesh(this.geometry, this.material);
    // this.plane.position.z = -10;
    // this.scene.add(this.plane);

  }

  stop() {
    this.isPlaying = false;
  }

  play() {
    if(!this.isPlaying){
      
      this.render()
      this.isPlaying = true;
    }
  }

  getPoint(event){
    this.np = event.clientX;
  }

  

  render() {
    if (!this.isPlaying) return;
    this.time += 0.05;
    // console.log(this.dataArray.getAverageFrequency);
    // analyser.getFrequencyData();
    const data = analyser.getAverageFrequency();
    
    // if (this.face1) this.face1.rotation.y += 0.01;
    // if(this.face1) this.face1.lookAt(this.pointOfIntersection);
    // if(this.face1) this.face1.lookAt((0,0,0));
    // if (this.face1) this.face1.rotation.y = THREE.MathUtils.lerp(this.face1.rotation.y, (this.point.x * Math.PI)/10 , 0.1);
    if (this.face1) this.face1.rotateY(0.02);
    if (this.face2) this.face2.rotateY(0.02);
    if (this.face3) this.face3.rotateY(0.02);
    // if (this.face3) this.face3.rotation.y += 0.01;
    // if(this.analyser) this.analyser.getByteFrequencyData(this.dataArray);
    
    
    // if(analyser) this.aa = this.ta + (data/128. - this.ta)/1000.;
    // if(analyser) this.aa = MathUtils.damp(this.ta, data/128., 0.9, 1/60.);
    if(analyser) this.aa = data/128.;


    this.material.uniforms.aa.value = this.aa;
    this.material2.uniforms.aa.value = this.aa;
    this.material3.uniforms.aa.value = this.aa;
    this.material.uniforms.time.value = this.time;
    this.material2.uniforms.time.value = this.time;
    this.material3.uniforms.time.value = this.time;
    requestAnimationFrame(this.render.bind(this));
    
    
    this.renderer.render(this.scene, this.camera);
    
  }
}

// new Sketch({
//   dom: document.getElementById("container")
// });

